import Logo from "../assets/logo.png"
import { Link} from 'react-scroll';

const navBar = () => {
  return (
    <div className="flex justify-between p-4 border-b-2 shadow-md items-center z-10 fixed w-screen bg-white ">
      <Link activeClass="active"
          to="header"
          spy={true}
          smooth={true}
          offset={40}
          duration={500}><div className=" flex items-center gap-4 text-primary"><img className="h-14" src={Logo} alt="" /> <h1 className="text-xl font-bold">JoeoludJames Ventures</h1> </div></Link>
      <div className="items-center font-semibold pr-4">
        <ul className="hidden lg:flex gap-12 text-primary hover:text-secondary">
          <Link activeClass="active"
          to="blog"
          spy={true}
          smooth={true}
          offset={40}
          duration={500}>Our Services</Link>
          <Link activeClass="active"
          to="aboutUs"
          spy={true}
          smooth={true}
          offset={40}
          duration={500}>About Us</Link>
          <Link activeClass="active"
          to="contactUs"
          spy={true}
          smooth={true}
          offset={40}
          duration={500}>Contact Us</Link>
        </ul>
      </div>
    </div>
  );
};

export default navBar;
