const AboutUs = () => {
  return (
    <div id="aboutUs" className="text-white bg-primary md:h-screen p-4 text-xl flex flex-col gap-4 justify-center">
      <h1 className="text-3xl font-bold text-center">Why Choose JoeoludJames Ventures?</h1>
      <div className="flex flex-col gap-8">
        <div>
          <h3 className="text-2xl font-medium mb-2">Quality Assurance</h3>
          <p>
          &emsp;We uphold the highest standards of quality across all our services.
          </p>
        </div>
        <div>
          <h3 className="text-2xl font-medium mb-2">
            - Customer-Centric Approach
          </h3>
          <p>
          &emsp;&emsp;Your satisfaction is our priority. We work closely with clients to
            understand and meet their specific needs.
          </p>
        </div>
        <div>
          <h3 className="text-2xl font-medium mb-4">
            - Innovation and Technology
          </h3>
          <p>
          &emsp;&emsp;We embrace innovation and leverage cutting-edge technology to
            enhance the efficiency and effectiveness of our services.
          </p>
        </div>
        <div>
          <h3 className="text-2xl font-medium mb-2">- Reliability</h3>
          <p>
          &emsp;&emsp;Count on us for reliable and timely service delivery. We understand
            the importance of meeting deadlines and exceeding expectations.
          </p>
        </div>
        <div>
          <h3 className="text-2xl font-medium mb-2">- Community Engagement</h3>
          <p>
          &emsp;&emsp;At JoeoludJames Ventures, we believe in giving back to the
            community. We actively participate in social initiatives to make a
            positive impact on the lives of those around us.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
