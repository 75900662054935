import Edu from "../assets/edu.jpg"

const Education = () => {
  return (
    <div className="border-2 p-6 rounded-lg">
      <h1 className="text-2xl font-medium mb-4">Educational Services</h1>
      <div className="flex items-center text-center ">
        <div className="lg:w-3/5 p-4">
          Empowering minds and shaping futures, our Educational Services
          division is dedicated to providing quality education tailored to the
          needs of our students. We offer a range of academic programs and
          training courses designed to enhance skills and knowledge across
          various disciplines. Whether you are an aspiring professional or
          seeking to expand your expertise, our educational services are crafted
          to meet your unique requirements.
        </div>
        <div className="h-full w-2/5 hidden lg:flex " ><img className="h-full " src={Edu} alt="" /></div>
      </div>
    </div>
  );
};

export default Education;
