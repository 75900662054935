const Header = () => {
  return (
    <div id="header" className="flex flex-col items-center text-center bg-primary text-white p-4 gap-4 h-72 justify-center mt-14">
      <h1 className="text-3xl font-bold">Welcome to JoeoludJames Ventures...</h1>
      <p className="text-xl">
        At JoeoludJames Ventures, we take pride in offering a diverse range of
        services to meet the varied needs of our clients. Our venture is committed to
        excellence in three key areas: Educational Services, Manufacturing of
        Textiles, and the Supply and Distribution of Petroleum Products.
      </p>
    </div>
  );
};

export default Header;
