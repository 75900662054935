import Textile from "../assets/textile.jpg";

const ManufactureTextile = () => {
  return (
    <div className="border-2 p-6 rounded-lg">
      <h1 className="text-2xl font-medium mb-4">Manufacturing of Textiles</h1>
      <div className="flex items-center text-center  ">
        <div className="h-full w-2/5 hidden lg:flex ">
          <img className="h-[400px] w-full" src={Textile} alt="" />
        </div>
        <div className="lg:w-3/5 p-4">
          Step into the world of premium textiles with JoeoludJames Ventures.
          Our state-of-the-art manufacturing facility produces high-quality
          textiles that cater to the diverse demands of the market. From fabrics
          that blend comfort with style to those designed for durability, we are
          committed to delivering excellence in every thread. Explore our
          textile offerings and experience the luxury of quality.
        </div>
      </div>
    </div>
  );
};

export default ManufactureTextile;
