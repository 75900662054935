import Oil from "../assets/oil.jpg"

const Petroleum = () => {
  return (
    <div className="border-2 p-6 rounded-lg">
      <h1 className="text-2xl font-medium mb-4">Supply and Distribution of Petroleum Products</h1>
      <div className="flex items-center text-center ">
      <div className="lg:w-3/5 p-4">
        In the dynamic energy sector, JoeoludJames Ventures stands as a reliable
        partner for the supply and distribution of petroleum products. Our
        commitment to safety, efficiency, and timely delivery sets us apart in
        the industry. Whether you require bulk petroleum products for industrial
        use or retail quantities, we have the infrastructure and expertise to
        meet your demands. Trust us for consistent and dependable petroleum
        product solutions.
      </div>
      <div className="h-full w-2/5 hidden lg:flex" >
      <img className="h-fit" src={Oil} alt="" />
      </div>
      </div>
    </div>
  );
};

export default Petroleum;
