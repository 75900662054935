const ContactUs = () => {
  return (
    <div id="contactUs" className="text-xl p-4">
      <p className="text-2xl font-medium mb-4">Explore the world of opportunities with JoeoludJames Ventures.</p>
      <p>
        Contact us (+234 703 352 1157) today to learn more about how our Educational Services,
        Manufacturing of Textiles, and Supply and Distribution of Petroleum
        Products can add value to your endeavors
      </p>
      <p className="text-center font-bold mt-7">Your success is our business!</p>
    </div>
  );
};

export default ContactUs;
