import Education from "./Education"
import ManufactureTextile from "./ManufactureTextile"
import Petroleum from "./Petroleum"

const Blog = () => {
  return (
    <div id="blog" className="p-4 flex flex-col gap-4 text-xl  justify-center">
        <h1 className="text-3xl font-bold text-center">OUR SERVICES</h1>
        <Education/>
        <ManufactureTextile/>
        <Petroleum/>
    </div>
  )
}

export default Blog