import './App.css';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Header from './components/Header';
import Blog from './components/Blog';
import AboutUs from './components/AboutUs';
import ContactUs from "./components/ContactUs"
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy } from 'react-scroll';

function App() {
  return (
    <div className="flex flex-col text-primary">
      <NavBar/>
      <Header/>
      <Blog/>
      <AboutUs/>
      <ContactUs/>
      <Footer/>
    </div>
  );
}

export default App;
